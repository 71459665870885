import axios, { AxiosInstance } from "axios";
import {
  DataProvider,
  HttpError,
  CrudOperators,
  CrudFilters,
  CrudSorting,
  LogicalFilter,
  CrudFilter,
} from "@pankod/refine-core";
import { stringify, parse } from "qs";
import get from "lodash/get";
import has from "lodash/has";
import mapValues from "lodash/mapValues";

const MIME_TYPE = "application/ld+json";

interface Violation {
  message: string;
  propertyPath: string;
}

const axiosInstance = axios.create({
  headers: { 'Accept': MIME_TYPE }
});
axiosInstance.interceptors.response.use(
  (response) => {
    return response;
  },
  (error) => {
    const customError: HttpError = {
      ...error,
      message:
        error.response?.data?.detail ||
        error.response?.data?.message ||
        error.response?.detail ||
        error.response?.message,
      statusCode: error.response?.status || error.status,
    };

    return Promise.reject(customError);
  }
);
/*
const axiosInstance2 = axios.create();
const token = localStorage.getItem(TOKEN_KEY);
  axiosInstance2.defaults.headers.common[
    "Authorization"
  ] = `Bearer ${token}`;
axiosInstance2.interceptors.response.use(  
  (response) => {    
    return response;
  },
  (error) => {    
    const customError: HttpError = {
      ...error,
      message: error.response?.data?.detail || error.response?.data?.message || error.response?.detail || error.response?.message,
      statusCode: error.response?.status,
    };    
  
    return Promise.reject(customError);
  }
);
*/

export const flatten = (data: any) => {
  return Array.isArray(data)
    ? data.map((v) => get(v, "@id", v))
    : get(data, "@id", data);
};
export const normalize = (data: any) => {
  if (has(data, "hydra:member")) {
    // Normalize items in collections
    data["hydra:member"] = data["hydra:member"].map((item: any) =>
      normalize(item)
    );

    return data;
  }

  // Flatten nested documents
  /*  return mapValues(data, (value) =>
    Array.isArray(value)
      ? value.map((v) => get(v, "@id", v))
      : get(value, "@id", value)
  );
*/
  return data;
};

const mapOperator = (operator: CrudOperators): string => {
  switch (operator) {
    case "ne":
    case "gte":
    case "or":
    case "and":
    case "lte":
      return `_${operator}`;
    case "contains":
      return "_like";
    case "eq":
      return "=";
    default:
      return "=";
  }
};

const mapDateOperator = (operator: CrudOperators): string => {
  switch (operator) {
    case "gte":
      return `[after]`;
    case "lte":
      return `[before]`;
    default:
      return `_${operator}`;
  }
};

const generateSort = (sort?: CrudSorting) => {
  if (sort && sort.length > 0) {
    const _sort: string[] = [];
    const _order: string[] = [];

    sort.map((item) => {
      _sort.push(item.field);
      _order.push(item.order);
    });

    return {
      _sort,
      _order,
    };
  }

  return;
};

const generateSortFilter = (sort?: CrudSorting) => {
  const querySortFilters: { [key: string]: string } = {};
  if (sort && sort.length > 0) {
    sort.map((item) => {
      querySortFilters[`order[${item.field}]=}`] = item.order;
    });
  }

  return querySortFilters;
};


const generateFilter = (filters?: CrudFilters, first?: boolean) => {
  let rawQuery = "";

  if (filters) {
    filters.map((filter) => {

      if (
        filter.operator !== "or" &&
        filter.operator !== "and" &&
        "field" in filter
      ) {
        const { field, operator, value } = filter;

        const mapedOperator = mapOperator(operator);

        if (operator === "ne") {
          rawQuery += `&not[${field}]=${value}`;
        }

        if (Array.isArray(value)) {
          value.map((val, index) => {
            rawQuery += `&${field}${mapedOperator}=${value}`;
          });
        } else {
          rawQuery += `&${field}${mapedOperator}${value}`;
        }
      }
      else {
        const { value } = filter;
        
        if(filter.operator === "and") {
          value && value.map((item) => {
            const { field, operator, value } = item as LogicalFilter;

            const mapedOperator = mapOperator(operator);

            rawQuery += `&${filter.operator}[${field}][]${mapedOperator}${value}`;
          })
        } else {
            rawQuery += ``;
        }

        if (filter.operator === "or" && filter.key !== "orDate") {
          value && value.map((item) => {
            const { field, operator, value } = item as LogicalFilter;

            const mapedOperator = mapOperator(operator);

            rawQuery += `&${filter.operator}[${field}][]${mapedOperator}${value}`;
          })
        } else {
            if (filter.operator === "or" && filter.key === "orDate" && value !== undefined)
            value && value.map((item) => {
              
              const { field, operator, value } = item as LogicalFilter;
              console.log(JSON.stringify("FilterValue:"));
              console.log(JSON.stringify(value));
              //console.log(JSON.stringify(filter.value));
              {value === undefined ? rawQuery += `` : rawQuery += `&or[${field}]=${value}`}
          
          
            })
            else {
              rawQuery += ``
            }
        }
      }
    });
  }

  const queryFilters = rawQuery;
  return queryFilters;
};


const JsonServer = (
  apiUrl: string,
  httpClient: AxiosInstance = axiosInstance
): DataProvider => ({
  getList: async ({ resource, pagination, filters, sort }) => {
    const url = `${apiUrl}/${resource}`;

    // pagination
    const current = pagination?.current || 1;
    const pageSize = pagination?.pageSize || 10;

    const queryFilters = generateFilter(filters);
    console.log(JSON.stringify(queryFilters));

    const query: {
      page: number;
      pageSize: number;
      //            _start: number;
      //            _end: number;
      _sort?: string;
      _order?: string;
    } = {
      page: current,
      pageSize: pageSize,

      //            _start: (current - 1) * pageSize,
      //            _end: current * pageSize,
    };
    /*const generatedSort = generateSort(sort);
    if (generatedSort) {
      const { _sort, _order } = generatedSort;
      query._sort = _sort.join(",");
      query._order = _order.join(",");
    }
*/
    const generatedSortFilters = generateSortFilter(sort);


    const { data } = await httpClient.get(
      `${url}?${stringify(query, {encodeValuesOnly: true,})}&${queryFilters}&${stringify(
        generatedSortFilters
      )}`,
    );


    /*const { data, headers } = await httpClient.get(
          sort && sort.length > 0
            ? `${url}?${stringify(query)}&${(queryFilters)}&${stringify(
              generatedSortFilters
            )}`
            : `${url}?${stringify(query)}&${stringify(queryFilters)}`,
          { headers: { Accept: MIME_TYPE } }
        )*/

    //       const total = +headers["x-total-count"];
    const resp = normalize(data);
    const total = has(resp, "hydra:totalItems") ? resp["hydra:totalItems"] : 0;

    return {
      //            data: normalize(data),
      data: has(resp, "hydra:member") ? resp["hydra:member"] : [],
      total,
    };
  },

  getMany: async ({ resource, ids }) => {
    const { data } = await httpClient.get(
      `${apiUrl}/${resource}?${stringify({ id: ids })}`
    );

    return {
      data,
    };
  },

  create: async ({ resource, variables }) => {
    const url = `${apiUrl}/${resource}`;

    const { data } = await httpClient.post(url, variables);

    return {
      data,
    };
  },

  createMany: async ({ resource, variables }) => {
    const response = await Promise.all(
      variables.map(async (param) => {
        const { data } = await httpClient.post(`${apiUrl}/${resource}`, param);
        return data;
      })
    );

    return { data: response };
  },

  update: async ({ resource, id, variables }) => {
    const url = `${apiUrl}/${resource}/${id}`;

    //const { data } = await httpClient.patch(url, variables,{  headers: { Accept: MIME_TYPE, 'Content-Type': 'application/merge-patch+json' },});
    const { data } = await httpClient.patch(url, variables);
    return {
      data,
    };
  },

  updateMany: async ({ resource, ids, variables }) => {
    const response = await Promise.all(
      ids.map(async (id) => {
        const { data } = await httpClient.patch(
          `${apiUrl}/${resource}/${id}`,
          variables
        );
        return data;
      })
    );

    return { data: response };
  },

  getOne: async ({ resource, id }) => {
    const url = `${apiUrl}/${resource}/${id}`;

    const { data } = await httpClient.get(url, {
      headers: { Accept: MIME_TYPE },
    });

    return {
      //data: has(data, "hydra:member") ? data["hydra:member"] : [],
      data,
    };
  },

  deleteOne: async ({ resource, id }) => {
    const url = `${apiUrl}/${resource}/${id}`;

    const { data } = await httpClient.delete(url);

    return {
      data,
    };
  },

  deleteMany: async ({ resource, ids }) => {
    const response = await Promise.all(
      ids.map(async (id) => {
        const { data } = await httpClient.delete(`${apiUrl}/${resource}/${id}`);
        return data;
      })
    );
    return { data: response };
  },

  getApiUrl: () => {
    return apiUrl;
  },

  custom: async ({ url, method, filters, sort, payload, query, headers }) => {
    let requestUrl = `${url}?`;

    if (sort) {
      const generatedSort = generateSort(sort);
      if (generatedSort) {
        const { _sort, _order } = generatedSort;
        const sortQuery = {
          _sort: _sort.join(","),
          _order: _order.join(","),
        };
        requestUrl = `${requestUrl}&${stringify(sortQuery)}`;
      }
    }

    if (filters) {
      const filterQuery = generateFilter(filters);
      requestUrl = `${requestUrl}&${stringify(filterQuery)}`;
    }

    if (query) {
      requestUrl = `${requestUrl}&${stringify(query)}`;
    }

    if (headers) {
      httpClient.defaults.headers = {
        ...httpClient.defaults.headers,
        ...headers,
      };
    }

    let axiosResponse;
    switch (method) {
      case "put":
      case "post":
      case "patch":
        axiosResponse = await httpClient[method](url, payload);
        break;
      case "delete":
        axiosResponse = await httpClient.delete(url);
        break;
      default:
        axiosResponse = await httpClient.get(requestUrl);
        break;
    }

    const { data } = axiosResponse;

    return Promise.resolve({ data });
  },
});

export default JsonServer;
