import { Show, Typography, Tag, Row, Col, Table, useTable, Space, Icons, Button } from "@pankod/refine-antd";
import { useOne, useShow } from "@pankod/refine-core";
import { IMekdata0 } from "interfaces/mekdata0";
import { IMekdata1 } from "interfaces/mekdata1";
import { Page, Text, View, Document, StyleSheet } from '@react-pdf/renderer';
import { PDFViewer } from '@react-pdf/renderer';
import { PDFDownloadLink } from "@react-pdf/renderer";
import { Link, Route, Routes } from 'react-router-dom';
import React, { useEffect, useState } from 'react'
import { useReactToPrint } from 'react-to-print';

const { Title, Text: Text2 } = Typography;

export const KeresesShow = () => {
    const { queryResult } = useShow<IMekdata0>();
    const { data, isLoading } = queryResult;
    const record = data?.data;

    const { tableProps, sorter, filters } = useTable<IMekdata1>({
        resource: "peoples"
    });

    const componentRef = React.useRef<HTMLDivElement>(null);
    const marginTop = "50px";
    const marginRight = "80px";
    const marginBottom = "50px";
    const marginLeft = "80px";
    const getPageMargins = () => {
        return `@page { margin: ${marginTop} ${marginRight} ${marginBottom} ${marginLeft} !important; }`;
    };

    const handlePrint = useReactToPrint({
        content: () =>
            componentRef && componentRef?.current ? componentRef.current : null,
    });

    const styles = StyleSheet.create({
        page: {
            flexDirection: 'row',
            backgroundColor: '#E4E4E4'
        },
        section: {
            margin: 10,
            padding: 10,
            flexGrow: 1
        }
    });



    const columns = [
        {
            title: "Megnevezés típusa",
            dataIndex: "mezokod",
            key: "mezokod",
            render: (text: any, data: any) => data?.data.mezokod == 1 ? "Születési hely (megyék/országok)" 
            : data?.data.mezokod == 2 ? "Születési helység" :
                data?.data.mezokod == 3 ? "Működési hely (megye/ország)" 
                : data?.data.mezokod == 4 ? "Működési helység"
                    : data?.data.mezokod == 5 ? "Foglalkozás" 
                    : data?.data.mezokod == 6 ? "Oktatási tevékenység"
                        : data?.data.mezokod == 7 ? "Tudományos fokozat" 
                        : data?.data.mezokod == 8 ? "Akadémiai tagság"
                            : data?.data.mezokod == 9 ? "Névváltozatok" 
                            : data?.data.mezokod == 10 ? "Álnevek" : data?.data.mezokod == 11 ? "Adateltérések"
                                : data?.data.mezokod == 12 ? "Egyéb adatok" 
                                : data?.data.mezokod == 13 ? "Hivatkozások" 
                                : data?.data.mezokod == 14 ? "Kitüntetések" : data?.data.mezokod
            //JSON.stringify(record)//
        },
        {
            title: "Megnevezés",
            dataIndex: "data.id",
            key: "data.id",
            render: (text: any, data: any) => data?.data.tnev,
            //JSON.stringify(record)//
        },

    ];


    const MyDocument = () => (
        <Document>
            <Page size="A4" style={styles.page}>
                <View style={styles.section}>
                    <Text>Név: {record?.nev}</Text>
                    <Text>Születési év: {record?.szev}</Text>
                    <Text>Hónap: {record?.szho}</Text>
                    <Text>Nap: {record?.sznap}</Text>
                </View>
                <View style={styles.section}>
                    <Text>Halálozási év: {record?.hev}</Text>
                    <Text>Hónap: {record?.hho}</Text>
                    <Text>Nap: {record?.hnap}</Text>
                </View>
                <View style={styles.section}>
                    <Table
                        pagination={false}
                        columns={columns}
                        rowKey="id"
                        dataSource={record?.peopleDatas}
                    />
                </View>
            </Page>
        </Document>
    );


    return (
        <Show isLoading={isLoading}>
            {/*<PDFViewer>
                <MyDocument />
            </PDFViewer>
            */}

            <div ref={componentRef}>
                <Row gutter={[16, 16]}>
                    <Col xl={10} xs={24}>
                        <Title level={5}>Név</Title>
                        <Text2>{record?.nev}</Text2>
                        <Title level={5}>Születési év</Title>
                        <Text2>{record?.szev == null ? "Nincs adat" : record?.szev}</Text2>

                        <Title level={5}>Hónap</Title>
                        <Text2>{record?.szho == null ? "Nincs adat" : record?.szho}</Text2>

                        <Title level={5}>Nap</Title>
                        <Text2>{record?.sznap == null ? "Nincs adat" : record?.sznap}</Text2>
                    </Col>
                    <Col xl={10} xs={24}>
                        <Title level={5}>Halálozási év</Title>
                        <Text2>{record?.hev == null ? "Nincs adat" : record?.hev}</Text2>

                        <Title level={5}>Hónap</Title>
                        <Text2>{record?.hho == null ? "Nincs adat" : record?.hho}</Text2>

                        <Title level={5}>Nap</Title>
                        <Text2>{record?.hnap == null ? "Nincs adat" : record?.hnap}</Text2>

                        {/*
                        <Title level={5}>Műveletek</Title>
                        <PDFDownloadLink style={{ textAlign: "center" }} document={<MyDocument />} fileName="somename.pdf">
                            {({ blob, url, loading, error }) =>
                                loading ? 'Dokumentum betöltése...' : <><Space> <Icons.FilePdfOutlined style={{ fontSize: '30px' }} /><Text>PDF letöltése</Text></Space> </>
                            }
                        </PDFDownloadLink>
                        */}
                    </Col>
                </Row>
                <br></br>

                <Table
                    pagination={false}
                    columns={columns}
                    rowKey="id"
                    dataSource={record?.peopleDatas}
                />
                <style>{getPageMargins()}</style>

            </div>
            <br></br>
            <Button icon={<Icons.FilePdfOutlined style={{ fontSize: "18px" }} />} type="primary" onClick={handlePrint}>Letöltés</Button>
        </Show>
    );
};

{/*<Show isLoading={isLoading}>
<Title level={5}>Név</Title>
<Text>{record?.nev}</Text>

<Title level={5}>Kód</Title>
<Text>
    <Tag>{ record?.mekdata1s[1].mezokod}</Tag>
</Text>

<Title level={5}>Category</Title>
<Text>{categoryData?.data.title}</Text>
</Show>*/}
