import {
  useTranslate,
  IResourceComponentsProps,
  useMany,
  useNavigation,
  usePermissions,
  CrudFilters,
  HttpError,
  useShow,
  useUpdate,
} from "@pankod/refine-core";
import { EditOutlined, EllipsisOutlined, SettingOutlined } from '@ant-design/icons';
import { Avatar, Card } from 'antd';
import React from 'react';
import {
  List,
  Table,
  TextField,
  useTable,
  getDefaultSortOrder,
  DateField,
  Space,
  EditButton,
  DeleteButton,
  useSelect,
  TagField,
  FilterDropdown,
  ShowButton,
  AntdList,
  useModalForm,
  Modal,
  Form,
  Radio,
  FormProps,
  Icons,
  Drawer,
  Show,
  useDrawerForm,
  Switch,
  useModal,
  RefreshButton,
  CreateButton,
  Grid,
  AutoComplete,
  Pagination,
  Divider,
  Popover
} from "@pankod/refine-antd";
import html2canvas from 'html2canvas';
import { jsPDF } from 'jspdf';
import ReactPDF, { Page, Text, View, Document, StyleSheet } from '@react-pdf/renderer';
import routerProvider from "@pankod/refine-react-router-v6";
import { useNavigate, useLocation } from "@pankod/refine-react-router-v6";
import { useReactToPrint } from 'react-to-print';
import { IPost, ICategory, IOptions } from "interfaces";
import { Col, Row, Tooltip } from 'antd';
import { AudioOutlined } from '@ant-design/icons';
import { Input, Select, Button } from 'antd';
import { SearchOutlined } from '@ant-design/icons';
import type { DatePickerProps } from 'antd';
import { DatePicker, Image } from 'antd';
import { Cascader, InputNumber } from 'antd';
import { UserOutlined, AntDesignOutlined, InboxOutlined } from '@ant-design/icons';
import { message, Upload, Typography } from 'antd';
import type { ColumnsType, TableProps } from 'antd/es/table';
import { UploadOutlined, FormOutlined, Loading3QuartersOutlined, CheckOutlined, CheckCircleFilled, CheckCircleOutlined, ExceptionOutlined } from '@ant-design/icons';
import { useState, useEffect } from 'react';
import type { RadioChangeEvent } from 'antd';
import { IMekdata0, IMekdata0Filter } from "interfaces/mekdata0";
import { IForras } from "interfaces/forras";
import { IMekdata1 } from "interfaces/mekdata1";
import { Tabs } from 'antd';
import type { TabsProps } from 'antd';
import { IMekdata2, IMekdata2Filter } from "interfaces/mekdata2";
import { useList } from "@pankod/refine-core";
import { Checkbox } from 'antd';
import type { CheckboxValueType } from 'antd/es/checkbox/Group';
import { mezoKodTipus } from "constants/type";
import { Steps, theme } from 'antd';
import { useSearchParams } from 'react-router-dom';
import { useRef } from 'react';

export const Kereses: React.FC<IResourceComponentsProps> = () => {
  const t = useTranslate();
  const { Meta } = Card;
  const { Option } = Select;
  const [isActive, setIsActive] = useState(false);
  const [isActive2, setIsActive2] = useState(false);
  const { Text, Link, Title } = Typography;
  const [selected, setSelected] = useState(1);
  const [selectedFilter, setSelectedFilter] = useState({});
  const { modalProps, show: modalShow, close } = useModal();

  const styles = StyleSheet.create({
    page: {
      flexDirection: 'row',
      backgroundColor: '#E4E4E4'
    },
    section: {
      margin: 10,
      padding: 10,
      flexGrow: 1
    }
  });


  const { tableProps: tableProps2, sorter: sorter2, filters: filters2, searchFormProps: searchFormProps2 } =
    useTable<IMekdata2, HttpError, IMekdata2Filter>({
      resource: "data",

      initialSorter: [
        {
          field: "mezokod",
          order: "desc",
        },
      ],
      onSearch: (params) => {
        const filters: CrudFilters = [];
        const { mezokod, tnev } = params;

        filters.push({
          field: "mezokod",
          operator: "eq",
          value: mezokod || undefined,
        });

        filters.push({
          field: "tnev",
          operator: "eq",
          value: tnev || undefined,
        });

        return filters;
      },
      syncWithLocation: true,
    });



  const [totalValue, setTotal] = useState();
  const onChangeTotalSelect = (value: any) => {
    console.log(`selected ${value}`);
    setTotal(value);
  };

  const [nameValue, setNameValue] = useState(1);
  const onChangeNameValue = (value: any) => {
    console.log(`selected ${value}`)
    setNameValue(value);

  };

  const { tableProps, sorter: tablesorter, filters: tablefilters, searchFormProps, setFilters, tableQueryResult } =
    useTable<IMekdata0, HttpError, IMekdata0Filter>({
      resource: "peoples",
      initialSorter: [
        {
          field: "nev",
          order: "asc"
        },
        {
          field: "szev",
          order: "asc"
        }
      ],
      onSearch: (params) => {
        const filters: CrudFilters = [];
        const { nev, getNevExact, hev, hho, hnap, szev, szho, sznap, szevOr,
          hevOr, mek1Tnev, mek1mezokod, ktars, mek0filters: mezofilters, mek0filters2: mezofiltersAnd, mek0filters3: mezofiltersNot, hhoOr,
          hnapOr, szhoOr, sznapOr, hevNe, hhoNe, hnapNe, szevNe, szhoNe, sznapNe, nameExact, nameStartsWith } = params;

        /** --------- Tulajdonságok szűrése */
        mezofiltersAnd?.map(
          (item: any, index: number) => {
            return filters.push({
              key: `andfilter${index}`,
              operator: "and",
              value:
                item.data.mezokod == 1 ?
                  [
                    {
                      field: "szulHelyMegye",
                      operator: "eq",
                      value: [item.data.mezokod, item.data.tkod].join('|')
                    }
                  ]
                  : item.data.mezokod == 2 ?
                    [
                      {
                        field: "szulHelyseg",
                        operator: "eq",
                        value: [item.data.mezokod, item.data.tkod].join('|')
                      }
                    ]
                    : item.data.mezokod == 3 ?
                      [
                        {
                          field: "mukHelyMegye",
                          operator: "eq",
                          value: [item.data.mezokod, item.data.tkod].join('|')
                        }
                      ]
                      : item.data.mezokod == 4 ?
                        [
                          {
                            field: "mukHelyseg",
                            operator: "eq",
                            value: [item.data.mezokod, item.data.tkod].join('|')
                          }
                        ]
                        : item.data.mezokod == 5 ?
                          [
                            {
                              field: "foglalkozas",
                              operator: "eq",
                              value: [item.data.mezokod, item.data.tkod].join('|')
                            }
                          ]
                          : item.data.mezokod == 6 ?
                            [
                              {
                                field: "oktTevekenyseg",
                                operator: "eq",
                                value: [item.data.mezokod, item.data.tkod].join('|')
                              }
                            ]
                            : item.data.mezokod == 7 ?
                              [
                                {
                                  field: "tudFokozat",
                                  operator: "eq",
                                  value: [item.data.mezokod, item.data.tkod].join('|')
                                }
                              ]
                              : item.data.mezokod == 8 ?
                                [
                                  {
                                    field: "akademiaiTag",
                                    operator: "eq",
                                    value: [item.data.mezokod, item.data.tkod].join('|')
                                  }
                                ]
                                : item.data.mezokod == 9 ?
                                  [
                                    {
                                      field: "nevvaltozat",
                                      operator: "eq",
                                      value: [item.data.mezokod, item.data.tkod].join('|')
                                    }
                                  ]
                                  : item.data.mezokod == 10 ?
                                    [
                                      {
                                        field: "alnevek",
                                        operator: "eq",
                                        value: [item.data.mezokod, item.data.tkod].join('|')
                                      }
                                    ]
                                    : item.data.mezokod == 11 ?
                                      [
                                        {
                                          field: "adatelteresek",
                                          operator: "eq",
                                          value: [item.data.mezokod, item.data.tkod].join('|')
                                        }
                                      ]
                                      : item.data.mezokod == 12 ?
                                        [
                                          {
                                            field: "egyebAdatok",
                                            operator: "eq",
                                            value: [item.data.mezokod, item.data.tkod].join('|')
                                          }
                                        ]
                                        : item.data.mezokod == 13 ?
                                          [
                                            {
                                              field: "hivatkozasok",
                                              operator: "eq",
                                              value: [item.data.mezokod, item.data.tkod].join('|')
                                            }
                                          ]
                                          : item.data.mezokod == 14 ?
                                            [
                                              {
                                                field: "kituntetesek",
                                                operator: "eq",
                                                value: [item.data.mezokod, item.data.tkod].join('|')
                                              }
                                            ] :
                                            [
                                              {
                                                field: "mezokodTkod",
                                                operator: "eq",
                                                value: [item.data.mezokod, item.data.tkod].join('|')
                                              }
                                            ]
            }
            );
          })
        mezofilters?.map(
          (item: any, index: number) => {
            return filters.push({
              key: `orfilter${index}`,
              operator: "or",
              value:
                item.data.mezokod == 1 ?
                  [
                    {
                      field: "szulHelyMegye",
                      operator: "eq",
                      value: [item.data.mezokod, item.data.tkod].join('|')
                    }
                  ]
                  : item.data.mezokod == 2 ?
                    [
                      {
                        field: "szulHelyseg",
                        operator: "eq",
                        value: [item.data.mezokod, item.data.tkod].join('|')
                      }
                    ]
                    : item.data.mezokod == 3 ?
                      [
                        {
                          field: "mukHelyMegye",
                          operator: "eq",
                          value: [item.data.mezokod, item.data.tkod].join('|')
                        }
                      ]
                      : item.data.mezokod == 4 ?
                        [
                          {
                            field: "mukHelyseg",
                            operator: "eq",
                            value: [item.data.mezokod, item.data.tkod].join('|')
                          }
                        ]
                        : item.data.mezokod == 5 ?
                          [
                            {
                              field: "foglalkozas",
                              operator: "eq",
                              value: [item.data.mezokod, item.data.tkod].join('|')
                            }
                          ]
                          : item.data.mezokod == 6 ?
                            [
                              {
                                field: "oktTevekenyseg",
                                operator: "eq",
                                value: [item.data.mezokod, item.data.tkod].join('|')
                              }
                            ]
                            : item.data.mezokod == 7 ?
                              [
                                {
                                  field: "tudFokozat",
                                  operator: "eq",
                                  value: [item.data.mezokod, item.data.tkod].join('|')
                                }
                              ]
                              : item.data.mezokod == 8 ?
                                [
                                  {
                                    field: "akademiaiTag",
                                    operator: "eq",
                                    value: [item.data.mezokod, item.data.tkod].join('|')
                                  }
                                ]
                                : item.data.mezokod == 9 ?
                                  [
                                    {
                                      field: "nevvaltozat",
                                      operator: "eq",
                                      value: [item.data.mezokod, item.data.tkod].join('|')
                                    }
                                  ]
                                  : item.data.mezokod == 10 ?
                                    [
                                      {
                                        field: "alnevek",
                                        operator: "eq",
                                        value: [item.data.mezokod, item.data.tkod].join('|')
                                      }
                                    ]
                                    : item.data.mezokod == 11 ?
                                      [
                                        {
                                          field: "adatelteresek",
                                          operator: "eq",
                                          value: [item.data.mezokod, item.data.tkod].join('|')
                                        }
                                      ]
                                      : item.data.mezokod == 12 ?
                                        [
                                          {
                                            field: "egyebAdatok",
                                            operator: "eq",
                                            value: [item.data.mezokod, item.data.tkod].join('|')
                                          }
                                        ]
                                        : item.data.mezokod == 13 ?
                                          [
                                            {
                                              field: "hivatkozasok",
                                              operator: "eq",
                                              value: [item.data.mezokod, item.data.tkod].join('|')
                                            }
                                          ]
                                          : item.data.mezokod == 14 ?
                                            [
                                              {
                                                field: "kituntetesek",
                                                operator: "eq",
                                                value: [item.data.mezokod, item.data.tkod].join('|')
                                              }
                                            ] :
                                            [
                                              {
                                                field: "mezokodTkod",
                                                operator: "eq",
                                                value: [item.data.mezokod, item.data.tkod].join('|')
                                              }
                                            ]
            }
            );
          })

        mezofiltersNot?.map(
          (item: any, index: number) => {
            return filters.push(
              item.data.mezokod == 1 ?
                {
                  field: "szulHelyMegye",
                  operator: "ne",
                  value: [item.data.mezokod, item.data.tkod].join('|')
                }
                : item.data.mezokod == 2 ?
                  {
                    field: "szulHelyseg",
                    operator: "ne",
                    value: [item.data.mezokod, item.data.tkod].join('|')
                  }
                  : item.data.mezokod == 3 ?
                    {
                      field: "mukHelyMegye",
                      operator: "ne",
                      value: [item.data.mezokod, item.data.tkod].join('|')
                    }
                    : item.data.mezokod == 4 ?
                      {
                        field: "mukHelyseg",
                        operator: "ne",
                        value: [item.data.mezokod, item.data.tkod].join('|')
                      }
                      : item.data.mezokod == 5 ?
                        {
                          field: "foglalkozas",
                          operator: "ne",
                          value: [item.data.mezokod, item.data.tkod].join('|')
                        }
                        : item.data.mezokod == 6 ?
                          {
                            field: "oktTevekenyseg",
                            operator: "ne",
                            value: [item.data.mezokod, item.data.tkod].join('|')
                          }
                          : item.data.mezokod == 7 ?
                            {
                              field: "tudFokozat",
                              operator: "ne",
                              value: [item.data.mezokod, item.data.tkod].join('|')
                            }
                            : item.data.mezokod == 8 ?
                              {
                                field: "akademiaiTag",
                                operator: "ne",
                                value: [item.data.mezokod, item.data.tkod].join('|')
                              }
                              : item.data.mezokod == 9 ?
                                {
                                  field: "nevvaltozat",
                                  operator: "ne",
                                  value: [item.data.mezokod, item.data.tkod].join('|')
                                }
                                : item.data.mezokod == 10 ?
                                  {
                                    field: "alnevek",
                                    operator: "ne",
                                    value: [item.data.mezokod, item.data.tkod].join('|')
                                  }
                                  : item.data.mezokod == 11 ?
                                    {
                                      field: "adatelteresek",
                                      operator: "ne",
                                      value: [item.data.mezokod, item.data.tkod].join('|')
                                    }
                                    : item.data.mezokod == 12 ?
                                      {
                                        field: "egyebAdatok",
                                        operator: "ne",
                                        value: [item.data.mezokod, item.data.tkod].join('|')
                                      }
                                      : item.data.mezokod == 13 ?
                                        {
                                          field: "hivatkozasok",
                                          operator: "ne",
                                          value: [item.data.mezokod, item.data.tkod].join('|')
                                        }
                                        : item.data.mezokod == 14 ?
                                          {
                                            field: "kituntetesek",
                                            operator: "ne",
                                            value: [item.data.mezokod, item.data.tkod].join('|')
                                          }
                                          : {
                                            field: "mezokodTkod",
                                            operator: "ne",
                                            value: [item.data.mezokod, item.data.tkod].join('|')
                                          }
            );
          })

        /** --------- "Vagy" feltétel */

        filters.push({
          key: "orDate",
          operator: "or",
          value: [
            {
              field: "hev",
              operator: "eq",
              value: hevOr || undefined
            },
            {
              field: "hho",
              operator: "eq",
              value: hhoOr || undefined
            },
            {
              field: "hnap",
              operator: "eq",
              value: hnapOr || undefined
            },

            {
              field: "szev",
              operator: "eq",
              value: szevOr || undefined
            },
            {
              field: "szho",
              operator: "eq",
              value: szhoOr || undefined
            },
            {
              field: "sznap",
              operator: "eq",
              value: sznapOr || undefined
            },
          ]
        })


        /** --------- "Egyenlő" feltétel */
        filters.push({
          field: "szev",
          operator: "eq",
          value: szev || undefined,
        });

        filters.push({
          field: "szho",
          operator: "eq",
          value: szho || undefined,
        });

        filters.push({
          field: "sznap",
          operator: "eq",
          value: sznap || undefined,
        });

        filters.push({
          field: "hev",
          operator: "eq",
          value: hev || undefined,
        });


        filters.push({
          field: "hho",
          operator: "eq",
          value: hho || undefined,
        });

        filters.push({
          field: "hnap",
          operator: "eq",
          value: hnap || undefined,
        });

        filters.push({
          field: "nev",
          operator: nameValue == 1 ? "eq" : "contains",
          value: nameValue == 1 ? nev : null,
        })

        filters.push({
          field: "ktars",
          operator: "eq",
          value: ktars,
        });


        filters.push({
          field: "nameStartsWith",
          operator: nameValue == 3 ? "eq" : "contains",
          value: nameValue == 3 ? nameStartsWith : null,
        });


        filters.push({
          field: "nameExact",
          operator: nameValue == 2 ? "eq" : "contains",
          value: nameValue == 2 ? nameExact : null,
        });

        /** ---------------------------- */


        /** --------- "De nem" feltétel */
        if (selected === 3) {
          filters.push({
            field: "szev",
            operator: "ne",
            value: szevNe || undefined,
          });

          filters.push({
            field: "szho",
            operator: "ne",
            value: szhoNe || undefined,
          });

          filters.push({
            field: "sznap",
            operator: "ne",
            value: sznapNe || undefined,
          });

          filters.push({
            field: "hev",
            operator: "ne",
            value: hevNe || undefined,
          });


          filters.push({
            field: "hho",
            operator: "ne",
            value: hhoNe || undefined,
          });

          filters.push({
            field: "hnap",
            operator: "ne",
            value: hnapNe || undefined,
          });
        }

        return filters;
      },
      syncWithLocation: true,
    });

  const onChange = (key: string) => {
    console.log(key);

  };
  const { queryResult, showId, setShowId } = useShow<IMekdata0>({
    resource: "peoples",
  });

  const componentRef = React.useRef<HTMLDivElement>(null);
  const marginTop = "50px";
  const marginRight = "80px";
  const marginBottom = "50px";
  const marginLeft = "80px";
  const getPageMargins = () => {
    return `@page { margin: ${marginTop} ${marginRight} ${marginBottom} ${marginLeft} !important; }`;
  };

  const handlePrint = useReactToPrint({
    content: () =>
      componentRef && componentRef?.current ? componentRef.current : null,
  });

  const { data, isLoading } = queryResult;
  const record = data?.data;

  const [visible, setVisible] = useState(false);

  const items: TabsProps['items'] = [
    {
      key: '1',
      label: `Keresési eredmény`,
      children:
        <>
          <Space>
            <Text style={{ fontSize: 14 }}><strong>Maximum megjelenített találatok száma:</strong></Text>
            <br></br>
            <Select
              style={{ width: 100 }}
              placeholder="Maximum megjelenített találatok száma"
              onChange={onChangeTotalSelect}
              value={totalValue}
              defaultValue={10}
            >
              <Option value={10}>10</Option>
              <Option value={50}>50</Option>
              <Option value={100}>100</Option>
              <Option value={150}>150</Option>
              <Option value={200}>200</Option>
              <Option value={250}>250</Option>
            </Select>
          </Space>
          <br></br>
          <br></br>
          <Table {...tableProps} rowKey="id" pagination={{
            ...tableProps.pagination,
            showTotal: (total, range) => `Találatok: ${tableQueryResult.data?.total} (${range[0]}-${range[1]})`,
            defaultCurrent: 1, total: totalValue
          }}>{/*dataSource={hasData ? data : []}*/}
            <Table.Column<IMekdata0> dataIndex="nev" title="Személy neve"
              render={(_, record) => (
                record.nev == null ? "Nincs adat" : record.nev
              )}
              sorter={{ multiple: 2 }}
              defaultSortOrder={getDefaultSortOrder("nev", tablesorter)}
            />
            <Table.Column<IMekdata0>
              dataIndex="szev"
              title="Születési év"
              render={(_, record) => (
                record.szev == null ? "Nincs adat" : record.szev
              )}
              sorter={{ multiple: 1 }}
              defaultSortOrder={getDefaultSortOrder("szev", tablesorter)}
            />
            <Table.Column<IMekdata0>
              dataIndex="szho"
              title="Hónap"
              sorter
              render={(_, record) => (
                record.szho == null ? "Nincs adat" : record.szho
              )} />
            <Table.Column<IMekdata0>
              dataIndex="sznap"
              title="Nap"
              sorter
              render={(_, record) => (
                record.sznap == null ? "Nincs adat" : record.sznap
              )} />
            <Table.Column<IMekdata0>
              dataIndex="hev"
              title="Halálozási dátum"
              sorter
              render={(_, record) => (
                record.hev == null ? "Nincs adat" : record.hev
              )} />
            <Table.Column<IMekdata0>
              dataIndex="hho"
              title="Hónap"
              sorter
              render={(_, record) => (
                record.hho == null ? "Nincs adat" : record.hho
              )} />
            <Table.Column<IMekdata0>
              dataIndex="hnap"
              title="Nap"
              sorter
              render={(_, record) => (
                record.hnap == null ? "Nincs adat" : record.hnap
              )} />
            <Table.Column<IMekdata0>
              title="Műveletek"
              dataIndex="actions"
              render={(_, record) => (
                <Space>
                  <ShowButton
                    size="small"
                    resourceNameOrRouteName="peoples"
                    recordItemId={record.id}
                    onClick={() => {
                      setShowId(record.id);
                      setVisible(true);
                    }}
                  />
                </Space>
              )}
            />
          </Table>
        </>
    }
  ];
  const columns: ColumnsType<IMekdata2> = [
    {
      title: "Megnevezés típusa",
      dataIndex: "mezokod",
      key: "mezokod",
      sorter: (function (a: any, b: any) {
        if (a?.mezokod < b?.mezokod) { return -1; }
        if (a?.mezokod > b?.mezokod) { return 1; }
        return 0;
      }),
      defaultSortOrder: "ascend",
      render: (text: any, data: any) => data?.mezokod == 1 ? "Születési hely (vármegyék/országok)" : data?.mezokod == 2 ? "Születési helység" :
        data?.mezokod == 3 ? "Működési hely (vármegye/ország)" : data?.mezokod == 4 ? "Működési helység"
          : data?.mezokod == 5 ? "Foglalkozás" : data?.mezokod == 6 ? "Oktatási tevékenység"
            : data?.mezokod == 7 ? "Tudományos fokozat" : data?.mezokod == 8 ? "Akadémiai tagság"
              : data?.mezokod == 9 ? "Névváltozatok" : data?.mezokod == 10 ? "Álnevek" : data?.mezokod == 11 ? "Adateltérések"
                : data?.mezokod == 12 ? "Egyéb adatok" : data?.mezokod == 13 ? "Hivatkozások" : data?.mezokod == 14 ? "Kitüntetések" : data?.mezokod
      //JSON.stringify(record)//
    },
    {
      key: 'data.tnev',
      title: "Megnevezés",
      dataIndex: "data.tnev",
      sorter: (function (a: any, b: any) {
        if (a?.data?.tnev < b?.data?.tnev) { return -1; }
        if (a?.data?.tnev > b?.data?.tnev) { return 1; }
        return 0;
      }),
      defaultSortOrder: "ascend",
      render: (text: any, data: any) => 
      <>
        {data?.data?.forrasTeljes != null ? 
        <Popover content={<><a href={data?.data?.forrasLink} target="_blank">{data?.data?.forrasTeljes}</a></>}>
          <Icons.BarsOutlined style={{fontSize: '22px', marginRight:'10px'}} /> <Text>{data?.data?.tnev}</Text>
        </Popover> : <Text>{data?.data?.tnev}</Text>}
      </>
      //JSON.stringify(record)//
    },
    {

    }
  ];


  const Filter: React.FC<{ formProps: FormProps }> = (props) => {
    const t = useTranslate();

    const handleChange = (selectedOption: any) => {
      setSelected(selectedOption);
      console.log(`Option selected:`, selectedOption);
    };

    /* Dátum kiválasztás */
    const [selectedNe, setSelectedNe] = useState(1);
    const handleChangeNe = (selectedOption: any) => {
      setSelectedNe(selectedOption);
      console.log(`Option selected:`, selectedOption);
    };

    const [selectedNe2, setSelectedNe2] = useState(1);
    const handleChangeNe2 = (selectedOption: any) => {
      setSelectedNe2(selectedOption);
      console.log(`Option selected:`, selectedOption);
    };
    /* Dátum kiválasztás */

    const { Link } = routerProvider;
    const [current, setCurrent] = useState(0);
    const { token } = theme.useToken();
    const onChangeStep = (value: number) => {
      console.log('onChange:', value);
      setCurrent(value);
    };

    const [message, setMessage] = useState('');
    //console.log(`INPUT:${message}`)
    const handleChangeInput = (event: any) => {
      setMessage(event.target.value);
    };

    const handleClickInput = () => {
      // 👇️ clear input value
      setMessage('');
    };


    const [error, setError] = useState('')

    const location = useLocation()
    const navigate = useNavigate()


    const [searchParams, setSearchParams] = useSearchParams();
    const queryParams = new URLSearchParams(location.search)
    console.log(`QUERY: ${queryParams}`)
    if (String(queryParams) !== 'pageSize=10&current=1&sorter%5B0%5D%5Bfield%5D=nev&sorter%5B0%5D%5Border%5D=asc&sorter%5B1%5D%5Bfield%5D=szev&sorter%5B1%5D%5Border%5D=asc') {
      setIsActive(true)
    } else (setIsActive(false))
    useEffect(() => {



      /*if (queryParams.has('error')) {
        setError('There was a problem.')
        queryParams.delete('error')
        navigate({
          search: queryParams.toString(),
        }, [])*/


      const removeErrorParam = () => {
        if (searchParams.has('error')) {
          searchParams.delete('error');
          setSearchParams(searchParams);
        }
      }
    }, []);



    const steps = [
      {
        icon: <Icons.PlusCircleOutlined />,
        title: 'És',
        content:
          <>
            <br></br>
            <Form.List
              name="mek0filters2"
            >
              {(fields, { add, remove }) => (
                <>
                  {fields.map((field, index) => (
                    <Space
                      key={field.key}
                      style={{
                        display: "grid",
                        marginBottom: 8,
                        marginLeft: 50,
                        marginRight: 50,
                      }}
                      align="baseline"
                    >
                      <Form.Item
                        {...field}
                        name={[field.name, "id"]}
                        fieldKey={[field.key, "id"]}
                        hidden
                      >
                        <Input />
                      </Form.Item>
                      <TextField value={`${index + 1}. Feltétel (és)`} />
                      <div style={{
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "center",
                        height: "100%"
                      }}>
                        <Form.Item
                          {...field}
                          name={[field.name, "data"]}
                        >
                          <FilterSelect />

                        </Form.Item>
                        <Button style={{ bottom: "23px", right: "520px" }} danger onClick={() => remove(field.name)} icon={<Icons.DeleteOutlined />} />

                      </div>
                    </Space>
                  ))}
                  <Form.Item>
                    <div style={{
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "center",
                      height: "100%"
                    }}>
                      <Button style={{ width: "90%" }} onClick={() => add()} block icon={<Icons.PlusOutlined />}>
                        'És' feltétel hozzáadása
                      </Button>
                    </div>
                  </Form.Item>
                </>
              )}
            </Form.List>

          </>,
      },
      {
        icon: <Icons.QuestionCircleOutlined />,
        title: 'Vagy',
        content:
          <>
            <br></br>
            <Form.List
              name="mek0filters"
            >
              {(fields, { add, remove }) => (
                <>
                  {fields.map((field, index) => (
                    <Space
                      key={field.key}
                      style={{
                        display: "grid",
                        marginBottom: 8,
                        marginLeft: 50,
                        marginRight: 50,
                      }}
                      align="baseline"
                    >
                      <Form.Item
                        {...field}
                        name={[field.name, "id"]}
                        fieldKey={[field.key, "id"]}
                        hidden
                      >
                        <Input />
                      </Form.Item>
                      <TextField value={`${index + 1}. Feltétel (vagy)`} />
                      <div style={{
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "center",
                        height: "100%"
                      }}>
                        <Form.Item
                          {...field}
                          name={[field.name, "data"]}
                        >

                          <FilterSelect />
                        </Form.Item>
                        <Button style={{ bottom: "23px", right: "520px" }} danger onClick={() => remove(field.name)} icon={<Icons.DeleteOutlined />} />
                      </div>
                    </Space>
                  ))}

                  <Form.Item>
                    <div style={{
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "center",
                      height: "100%"
                    }}>
                      <Button type="default" style={{ width: "90%" }} onClick={() => add()} block icon={<Icons.PlusOutlined />}>
                        'Vagy' feltétel hozzáadása
                      </Button>
                    </div>
                  </Form.Item>
                </>
              )}
            </Form.List>
          </>,
      },
      {
        icon: <Icons.CloseCircleOutlined />,
        title: 'De nem',
        content:
          <>
            <br></br>
            <Form.List
              name="mek0filters3"
            >
              {(fields, { add, remove }) => (
                <>
                  {fields.map((field, index) => (
                    <Space
                      key={field.key}
                      style={{
                        display: "grid",
                        marginBottom: 8,
                        marginLeft: 50,
                        marginRight: 50,
                      }}
                      align="baseline"
                    >
                      <Form.Item
                        {...field}
                        name={[field.name, "id"]}
                        fieldKey={[field.key, "id"]}
                        hidden
                      >
                        <Input />
                      </Form.Item>
                      <TextField value={`${index + 1}. Feltétel (vagy)`} />
                      <div style={{
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "center",
                        height: "100%"
                      }}>
                        <Form.Item
                          {...field}
                          name={[field.name, "data"]}
                        >

                          <FilterSelect />
                        </Form.Item>
                        <Button style={{ bottom: "23px", right: "520px" }} danger onClick={() => remove(field.name)} icon={<Icons.DeleteOutlined />} />
                      </div>
                    </Space>
                  ))}

                  <Form.Item>
                    <div style={{
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "center",
                      height: "100%"
                    }}>
                      <Button type="default" style={{ width: "90%" }} onClick={() => add()} block icon={<Icons.PlusOutlined />}>
                        'De nem' feltétel hozzáadása
                      </Button>
                    </div>
                  </Form.Item>
                </>
              )}
            </Form.List>
          </>,
      }
    ];

    const items = steps.map((item) => ({ key: item.title, title: item.title, icon: item?.icon }));

    const contentStyle: React.CSSProperties = {
      color: token.colorTextTertiary,
      backgroundColor: token.colorFillAlter,
      borderRadius: token.borderRadiusLG,
      border: `1px dashed ${token.colorBorder}`,
      marginTop: 16,
    };



    /*const ref = useRef(null);
  
    const handleClick = () => {
      // 👇️ reset input field's value
      ref.current.value = '';
    };
  */

    const [form] = Form.useForm();
    const resetFields = () => {
      props.formProps.form?.resetFields();
    };

    
    const [inputValue, setInputValue] = useState('')
    const onSearchInput = (value: any) => {
      setInputValue('')
    }
    const { Search } = Input

    return (
      <>
        <Form layout="vertical" {...props.formProps}
          onFinish={(values) => {
            /*if(nameValue === 2){
              props.formProps.form?.setFieldValue("nev", "");
            }*/
            values = props.formProps.form?.getFieldsValue(true);
            return props.formProps.onFinish?.(values);
          }}
        >

          <Row gutter={[20, 20]} align="top">
            <Col xl={5}>
              <Form.Item
                label="Név hasonlítás">
                <Select
                  onChange={onChangeNameValue}
                  value={nameValue}
                  style={{ width: 180 }}
                >
                  <Option value={1}>Bármely előfordulás</Option>
                  <Option value={2}>Teljes egyezés</Option>
                  <Option value={3}>Mező elejével egyező</Option>
                </Select>
              </Form.Item>
            </Col>
            <Col xl={5}>
              <Form.Item label="Keresett személy neve" name={nameValue === 1 ? "nev" : nameValue === 2 ? "nameExact" : nameValue === 3 ? "nameStartsWith" : "nev"}>
                <Input
                  allowClear
                  placeholder="Név"
                  prefix={<Icons.SearchOutlined />}
                  style={{ width: "100%" }}
                />
              </Form.Item>
            </Col>
            <Col xl={5}>
              <Form.Item
                label="Kortárs szűrés"
                name="ktars"

              >
                <Select
                  //style={{width:"50%"}}                  
                  defaultValue={""}
                  options={[
                    {
                      label: "Mindenki",
                      value: ""
                    },
                    {
                      label: "Kortárs",
                      value: true,
                    },
                    {
                      label: "Nem kortárs",
                      value: false,
                    },
                  ]}
                />
              </Form.Item>
            </Col>
            <Col xs={28} xl={28} md={13}>
              <div>
                {selected == 1 ?
                  <><Space>
                    <Form.Item
                      label="Születési év"
                      name="szev"
                    >
                      <Input
                        allowClear
                        placeholder="év"
                        style={{
                          width: "100%"
                        }}
                      />
                    </Form.Item>
                    <Form.Item
                      label="Hónap"
                      name="szho"
                    >
                      <Input
                        allowClear
                        placeholder="hónap"
                        style={{
                          width: "100%"
                        }}
                      />
                    </Form.Item>


                    <Form.Item
                      label="Nap"
                      name="sznap"
                    >
                      <Input
                        allowClear
                        placeholder="nap"
                        style={{
                          width: "100%"
                        }}
                      />
                    </Form.Item>
                  </Space>
                  </>
                  : selected == 2 ?
                    <>
                      <Space>
                        <Form.Item
                          label="Születési év"
                          name="szevOr"
                        >
                          <Input
                            allowClear
                            placeholder="év"
                            style={{
                              width: "100%"
                            }}
                          />
                        </Form.Item>

                        <Form.Item
                          label="Hónap"
                          name="szhoOr"
                        >
                          <Input
                            allowClear
                            placeholder="hónap"
                            style={{
                              width: "100%"
                            }}
                          />
                        </Form.Item>


                        <Form.Item
                          label="Nap"
                          name="sznapOr"
                        >
                          <Input
                            allowClear
                            placeholder="nap"
                            style={{
                              width: "100%"
                            }}
                          />
                        </Form.Item>
                      </Space>
                    </>
                    : selected == 3 ?
                      <>
                        <Space>
                          <Form.Item
                            label="Dátum kiválasztása">
                            <Select defaultValue={1}
                              style={{ width: 120 }}
                              onChange={handleChangeNe2}
                              value={selectedNe2}
                              options={[
                                { value: 1, label: 'Születés' },
                                { value: 2, label: 'Halálozás' }
                              ]}
                            />
                          </Form.Item>
                          <br></br>
                          <br></br>
                          {selectedNe2 == 1 ?
                            <>
                              <Form.Item
                                label="Születési év"
                                name="szev"
                              >
                                <Input
                                  allowClear
                                  placeholder="év"
                                  style={{
                                    width: "100%"
                                  }}
                                />
                              </Form.Item>

                              <Form.Item
                                label="Hónap"
                                name="szho"
                              >
                                <Input
                                  allowClear
                                  placeholder="hónap"
                                  style={{
                                    width: "100%"
                                  }}
                                />
                              </Form.Item>


                              <Form.Item
                                label="Nap"
                                name="sznap"
                              >
                                <Input
                                  allowClear
                                  placeholder="nap"
                                  style={{
                                    width: "100%"
                                  }}
                                />
                              </Form.Item>
                            </>
                            : selectedNe2 == 2 ?
                              <>
                                <Form.Item
                                  label="Halálozási év"
                                  name="hev"
                                >

                                  <Input
                                    allowClear
                                    placeholder="év"
                                    style={{
                                      width: "100%"
                                    }}
                                  />
                                </Form.Item>

                                <Form.Item
                                  label="Hónap"
                                  name="hho"
                                >
                                  <Input
                                    allowClear
                                    placeholder="hónap"
                                    style={{
                                      width: "100%"
                                    }}
                                  />
                                </Form.Item>


                                <Form.Item
                                  label="Nap"
                                  name="hnap"
                                >
                                  <Input
                                    allowClear
                                    placeholder="nap"
                                    style={{
                                      width: "100%"
                                    }}
                                  />
                                </Form.Item>
                              </> : ""
                          }
                        </Space>

                        {/*<Space>
                            <Form.Item
                              label="Születési év"
                              name="szev"
                            >
                              <Input
                                allowClear
                                placeholder="év"
                                style={{
                                  width: "100%"
                                }}
                              />
                            </Form.Item>

                            <Form.Item
                              label="Hónap"
                              name="szho"
                            >
                              <Input
                                allowClear
                                placeholder="hónap"
                                style={{
                                  width: "100%"
                                }}
                              />
                            </Form.Item>


                            <Form.Item
                              label="Nap"
                              name="sznap"
                            >
                              <Input
                                allowClear
                                placeholder="nap"
                                style={{
                                  width: "100%"
                                }}
                              />
                            </Form.Item>
                          </Space>*/}
                      </> : ""
                }
              </div>
            </Col>

            <Col xs={48} xl={30} md={24}>
              <Form.Item
                label="Dátum kapcsolat">
                <Select defaultValue={1}
                  style={{ width: 120 }}
                  onChange={handleChange}
                  value={selected}
                  options={[
                    { value: 1, label: 'és' },
                    { value: 2, label: 'vagy' },
                    { value: 3, label: 'de nem' },
                  ]}
                />
              </Form.Item>
            </Col>

            <Col xs={28} xl={28} md={13}>
              <div>
                {selected == 1 ?
                  <>
                    <Space>
                      <Form.Item
                        label="Halálozási év"
                        name="hev"
                      >

                        <Input
                          allowClear
                          placeholder="év"
                          style={{
                            width: "100%"
                          }}
                        />
                      </Form.Item>

                      <Form.Item
                        label="Hónap"
                        name="hho"
                      >
                        <Input
                          allowClear
                          placeholder="hónap"
                          style={{
                            width: "100%"
                          }}
                        />
                      </Form.Item>


                      <Form.Item
                        label="Nap"
                        name="hnap"
                      >
                        <Input
                          allowClear
                          placeholder="nap"
                          style={{
                            width: "100%"
                          }}
                        />
                      </Form.Item>
                    </Space>
                  </>
                  : selected == 2 ?
                    <>
                      <Space>
                        <Form.Item
                          label="Halálozási év"
                          name="hevOr"
                        >
                          <Input
                            allowClear
                            placeholder="év"
                            style={{
                              width: "100%"
                            }}
                          />
                        </Form.Item>

                        <Form.Item
                          label="Hónap"
                          name="hhoOr"
                        >
                          <Input
                            allowClear
                            placeholder="hónap"
                            style={{
                              width: "100%"
                            }}
                          />
                        </Form.Item>


                        <Form.Item
                          label="Nap"
                          name="hnapOr"
                        >
                          <Input
                            allowClear
                            placeholder="nap"
                            style={{
                              width: "100%"
                            }}
                          />
                        </Form.Item>
                      </Space>
                    </>
                    : selected == 3 ?
                      <>
                        <Space>
                          <Form.Item
                            label="Dátum kiválasztása">
                            <Select defaultValue={1}
                              style={{ width: 120 }}
                              onChange={handleChangeNe}
                              value={selectedNe}
                              options={[
                                { value: 1, label: 'Születés' },
                                { value: 2, label: 'Halálozás' }
                              ]}
                            />
                          </Form.Item>
                          <br></br>
                          <br></br>
                          {selectedNe == 1 ?
                            <>
                              <Form.Item
                                label="Születési év"
                                name="szevNe"
                              >
                                <Input
                                  allowClear
                                  placeholder="év"
                                  style={{
                                    width: "100%"
                                  }}
                                />
                              </Form.Item>

                              <Form.Item
                                label="Hónap"
                                name="szhoNe"
                              >
                                <Input
                                  allowClear
                                  placeholder="hónap"
                                  style={{
                                    width: "100%"
                                  }}
                                />
                              </Form.Item>


                              <Form.Item
                                label="Nap"
                                name="sznapNe"
                              >
                                <Input
                                  allowClear
                                  placeholder="nap"
                                  style={{
                                    width: "100%"
                                  }}
                                />
                              </Form.Item>
                            </>
                            : selectedNe == 2 ?
                              <>
                                <Form.Item
                                  label="Halálozási év"
                                  name="hevNe"
                                >

                                  <Input
                                    allowClear
                                    placeholder="év"
                                    style={{
                                      width: "100%"
                                    }}
                                  />
                                </Form.Item>

                                <Form.Item
                                  label="Hónap"
                                  name="hhoNe"
                                >
                                  <Input
                                    allowClear
                                    placeholder="hónap"
                                    style={{
                                      width: "100%"
                                    }}
                                  />
                                </Form.Item>


                                <Form.Item
                                  label="Nap"
                                  name="hnapNe"
                                >
                                  <Input
                                    allowClear
                                    placeholder="nap"
                                    style={{
                                      width: "100%"
                                    }}
                                  />
                                </Form.Item>
                              </> : ""
                          }
                        </Space>
                      </> : ""
                }
              </div>
            </Col>
          </Row>
          <Steps onChange={onChangeStep} current={current} items={items} />
          <div style={contentStyle}>{steps[current].content}</div>
          <br></br>
          <Row gutter={[10, 40]} align="bottom">
            <Col xs={24} xl={24} md={8}>
              <Form.Item>
                <Space>

                 
                  {String(queryParams) === 'pageSize=10&current=1&sorter%5B0%5D%5Bfield%5D=nev&sorter%5B0%5D%5Border%5D=asc&sorter%5B1%5D%5Bfield%5D=szev&sorter%5B1%5D%5Border%5D=asc' ? <Button htmlType="submit" style={{ width: "100%" }} type="primary" onClick={handleClickInput}>
                    {t("buttons.filter")}
                  </Button>
                    :
                    <Link to="/">
                      <Button type="primary">Új lekérdezés</Button>
                    </Link>
                  }


                </Space>
              </Form.Item>
            </Col>
          </Row>
        </Form>
      </>
    );
  };

  const { useBreakpoint } = Grid;

  type FilterSelectProps = {
    value?: IMekdata2;
    onChange?: (filter?: IMekdata2) => void;
    disabled?: boolean;
  };

  /**
   * @param value initialValue passed by parent Form.Item.
   * @param onChange a callback for Form.Item to read its child component's value.
   * @param disabled
   * @constructor
   */
  const FilterSelect: React.FC<FilterSelectProps> = ({
    value,
    onChange,
    disabled,
  }) => {
    const t = useTranslate();
    const [filter, setFilter] = useState<IMekdata2 | undefined>(value);
    const { tableProps, sorter, filters, searchFormProps: searchFormProps2 } = useTable<
      IMekdata2,
      HttpError,
      IMekdata2Filter
    >({
      resource: "data",
      initialSorter: [
        {
          field: "id",
          order: "desc",
        },
      ],

      onSearch: (params) => {
        const filters: CrudFilters = [];
        const { tnev, mezokod, TNameExact, TNameStartsWith, MezokodExact } = params;

        filters.push({
          field: "tnev",
          operator: "eq",
          value: tnev || undefined,
        });

        filters.push({
          field: "TNameExact",
          operator: "eq",
          value: TNameExact || undefined,
        });

        filters.push({
          field: "TNameStartsWith",
          operator: "eq",
          value: TNameStartsWith || undefined,
        });

        filters.push({
          field: "MezokodExact",
          operator: "eq",
          value: MezokodExact,
        });

        return filters;
      },
      syncWithLocation: false,
    });
    const { xl } = useBreakpoint();
    const [selectedRowKeys, setSelectedRowKeys] = useState<React.Key[]>([
      value?.id ?? "",
    ]);

    const onSelectChange = (selectedRowKeys: React.Key[]) => {
      setSelectedRowKeys(selectedRowKeys);
    };

    const start = () => {
      setLoading(true);
      // ajax request after empty completing
      setTimeout(() => {
        setSelectedRowKeys([]);
        setLoading(false);
      }, 1000);
    };

    const [loading, setLoading] = useState(false);

    const rowSelection = {
      selectedRowKeys,
      onChange: onSelectChange,
    };

    const {
      modalProps: filterSelectModal,
      show: filterSelectShow,
      close: filterSelectClose,
    } = useModal();

    const { setShowId, queryResult: filterQueryResult } = useShow<IMekdata2>({
      resource: "data",

      id: filter?.id,
    });

    let promise = new Promise(function (resolve, reject) {
      if (typeof onChange === "function") {
        resolve(onChange(filter));
      } else {
        resolve(true);
      }
    });
    useEffect(() => {
      if (value && filter?.id !== value?.id) {
        setFilter(value);
      }
    }, [value]);

    useEffect(() => {
      if (filterQueryResult.isFetched) {
        setFilter(filterQueryResult.data?.data);
      }
    }, [filterQueryResult]);

    useEffect(() => {
      if (filterQueryResult.isFetched && !filterQueryResult.isError) {
        promise.then(filterSelectClose);
      }
    }, [filter]);

    const hasSelected = selectedRowKeys.length > 0;

    const change = async (selectedRowKeys: React.Key[]) => {
      if (filter?.id !== selectedRowKeys[0]) setShowId(selectedRowKeys[0]);
      else filterSelectClose();
    };
    const [value2, setValue2] = useState(1);

    const onChange2 = (e: RadioChangeEvent) => {
      console.log('radio checked', e.target.value);
      setValue2(e.target.value);
    };


    const children: any = [];
    const Lists = ["és", "vagy", "de nem"];

    Lists.map((item, index) => {
      children.push(<Option key={index}>{item}</Option>);

    })
    function handleChange2(value: any) {
      console.log("filter:", value)
    }

    return (
      <>
        <Space>
          <Tooltip
            title={
              <Card
                title={
                  <Space
                    direction="vertical"
                    style={{
                      textAlign: "center",
                      width: "100%",
                    }}
                  >

                    <Space
                      direction="vertical"
                      style={{
                        width: "100%",
                        textAlign: xl ? "unset" : "center",
                      }}
                    >
                      <Typography.Text>
                        {filter?.mezokod == 1 ? <> <Icons.HomeOutlined /> Születési hely<br></br> (vármegyék/országok)</> : filter?.mezokod == 2 ? <> <Icons.HomeOutlined /> Születési helység</> :
                          filter?.mezokod == 3 ? <><Icons.GlobalOutlined /> Működési hely<br></br>(vármegye/ország)</> : filter?.mezokod == 4 ? <><Icons.GlobalOutlined /> Működési helység</>
                            : filter?.mezokod == 5 ? <><Icons.FolderOutlined /> Foglalkozás</> : filter?.mezokod == 6 ? <><Icons.FolderOutlined /> Oktatási tevékenység</>
                              : filter?.mezokod == 7 ? <><Icons.BookOutlined /> Tudományos fokozat</> : filter?.mezokod == 8 ? <><Icons.BookOutlined /> Akadémiai tagság</>
                                : filter?.mezokod == 9 ? <><Icons.UserOutlined /> Névváltozatok </> : filter?.mezokod == 10 ? <><Icons.UserOutlined /> Álnevek</> : filter?.mezokod == 11 ? <><Icons.UserOutlined /> Adateltérések</>
                                  : filter?.mezokod == 12 ? <><Icons.MenuOutlined /> Egyéb adatok</> : filter?.mezokod == 13 ? <><Icons.NumberOutlined /> Hivatkozások </> : filter?.mezokod == 14 ? <><Icons.TrophyOutlined /> Kitüntetések </> : filter?.mezokod}
                      </Typography.Text>
                    </Space>
                  </Space>
                }
                bordered={false}
                style={{ height: "100%" }}
              >
                <Space
                  direction="vertical"
                  style={{ width: "100%", height: "100%" }}
                >
                  <Space
                    direction="vertical"
                    style={{
                      width: "100%",
                      textAlign: xl ? "unset" : "center",
                    }}
                  >

                  </Space>
                </Space>
              </Card>
            }
          >

          </Tooltip>
          <Form.Item>
            {" "}
            {!disabled && (
              <Button
                style={{ right: "160px" }}
                icon={<Icons.FolderAddOutlined />}
                onClick={() => {
                  filterSelectShow();
                }}
              ></Button>
            )}


            <Space>

              <Input style={{ width: "170%", right: "95px" }} value={filter?.mezokod == 1 ? "Születési hely (vármegyék/országok)" : filter?.mezokod == 2 ? "Születési helység" :
                filter?.mezokod == 3 ? "Működési hely (vármegye/ország)" : filter?.mezokod == 4 ? "Működési helység"
                  : filter?.mezokod == 5 ? "Foglalkozás" : filter?.mezokod == 6 ? "Oktatási tevékenység"
                    : filter?.mezokod == 7 ? "Tudományos fokozat" : filter?.mezokod == 8 ? "Akadémiai tagság"
                      : filter?.mezokod == 9 ? "Névváltozatok" : filter?.mezokod == 10 ? "Álnevek" : filter?.mezokod == 11 ? "Adateltérések"
                        : filter?.mezokod == 12 ? "Egyéb adatok" : filter?.mezokod == 13 ? "Hivatkozások" : filter?.mezokod == 14 ? "Kitüntetések" : filter?.mezokod} />
              <Input style={{ width: "170%", left: "40px" }} value={filter?.tnev} />

            </Space>

          </Form.Item>

        </Space>

        <Modal
          width={1200}
          {...filterSelectModal}
          onCancel={filterSelectClose}
          onOk={() => {
            change(selectedRowKeys);
          }}
        >

          <List
            title="Lista"
            resource="data"
            headerProps={{
              extra: <CreateButton hidden={true} />,
            }}
          >

            <Filter2 formProps={searchFormProps2} />

            {/*<Button type="primary" onClick={start} disabled={!hasSelected} loading={loading}>
                  Reload
            </Button>
          */}
            <Table
              {...tableProps}
              rowKey="id"
              rowSelection={{
                type: "radio",
                ...rowSelection,
              }}
            >

              <Table.Column
                dataIndex="mezokod"
                key="mezokod"
                title="Típus"
                render={(value) => <TextField value={
                  value == 1 ? "Születési hely (vármegyék/országok)" : value == 2 ? "Születési helység" :
                    value == 3 ? "Működési hely (vármegye/ország)" : value == 4 ? "Működési helység"
                      : value == 5 ? "Foglalkozás" : value == 6 ? "Oktatási tevékenység"
                        : value == 7 ? "Tudományos fokozat" : value == 8 ? "Akadémiai tagság"
                          : value == 9 ? "Névváltozatok" : value == 10 ? "Álnevek" : value == 11 ? "Adateltérések"
                            : value == 12 ? "Egyéb adatok" : value == 13 ? "Hivatkozások" : value == 14 ? "Kitüntetések" : value
                } />}
              />
              <Table.Column
                dataIndex="tnev" title="Megnevezés" render={(value) => <TextField value={value} />} />

              <Table.Column
                dataIndex="darab" title="Darab" render={(value) => <TextField value={value} />} />
            </Table>
          </List>
        </Modal>
      </>
    );
  };

  const Filter2: React.FC<{ formProps: FormProps }> = (props) => {
    const { Option } = Select;
    const t = useTranslate();
    const [value, setValue] = useState(1);
    const form = props?.formProps?.form;//Form.useForm();

    const onFilterChange = (value: any) => {
      switch (value) {
        case 1:
          form?.setFieldsValue({ MezokodExact: 1 });
          break;
        case 2:
          form?.setFieldsValue({ MezokodExact: 2 });
          break;
        case 3:
          form?.setFieldsValue({ MezokodExact: 3 });
          break;
        case 4:
          form?.setFieldsValue({ MezokodExact: 4 });
          break;
        case 5:
          form?.setFieldsValue({ MezokodExact: 5 });
          break;
        case 6:
          form?.setFieldsValue({ MezokodExact: 6 });
          break;
        case 7:
          form?.setFieldsValue({ MezokodExact: 7 });
          break;
        case 8:
          form?.setFieldsValue({ MezokodExact: 8 });
          break;
        case 9:
          form?.setFieldsValue({ MezokodExact: 9 });
          break;
        case 10:
          form?.setFieldsValue({ MezokodExact: 10 });
          break;
        case 11:
          form?.setFieldsValue({ MezokodExact: 11 });
          break;
        case 12:
          form?.setFieldsValue({ MezokodExact: 12 });
          break;
        case 13:
          form?.setFieldsValue({ MezokodExact: 13 });
          break;
        case 14:
          form?.setFieldsValue({ MezokodExact: 14 });
          break;

        default:
      }
    };



    const onFinish = (values: any) => {
      console.log(values);
    };
    const onChange = (e: RadioChangeEvent) => {
      console.log('radio checked', e.target.value);
      setValue(e.target.value);
    };
    return (
      <>
        <Row gutter={[20, 20]} align="top">
          <Form layout="vertical" form={form} {...props.formProps}>

            <Col xl={24}>
              <Text strong >Megnevezés keresése</Text>
              <Form.Item style={{ paddingTop: 10 }} name="tnev" >
                <Input
                  style={{ width: 250 }}
                  allowClear
                  placeholder="Bármely előfordulás"
                  prefix={<Icons.SearchOutlined />}

                />
              </Form.Item>


              <Form.Item style={{ paddingTop: 10 }} name="TNameStartsWith" >
                <Input
                  style={{ width: 250 }}
                  allowClear
                  placeholder="Mező elejével egyező"
                  prefix={<Icons.SearchOutlined />}

                />
              </Form.Item>

              <Form.Item style={{ paddingTop: 10 }} name="TNameExact" >
                <Input
                  style={{ width: 250 }}
                  allowClear
                  placeholder="Teljes egyezés"
                  prefix={<Icons.SearchOutlined />}

                />
              </Form.Item>

              <Form.Item name="MezokodExact">
                <Input
                  hidden
                  allowClear
                  placeholder="Megnevezés"
                  prefix={<Icons.SearchOutlined />}

                />
              </Form.Item>
            </Col>


            <Col xl={12}>
              <Text strong >Szűkítés</Text>
              <Form.Item name="filter">
                <Select
                  style={{ width: 250, paddingTop: 10 }}
                  placeholder="Válassza ki a szűrési feltételt!"
                  onChange={onFilterChange}
                  allowClear
                >
                  <Option value={1}>Születési hely (vármegye/ország)</Option>
                  <Option value={2}>Születési hely (helység)</Option>
                  <Option value={3}>Működési hely (vármegye/ország)</Option>
                  <Option value={4}>Működési hely (helység)</Option>
                  <Option value={5}>Foglalkozás</Option>
                  <Option value={6}>Oktatási tevékenység</Option>
                  <Option value={7}>Tudományos fokozat</Option>
                  <Option value={8}>Akadémiai tagság</Option>
                  <Option value={9}>Névváltozatok</Option>
                  <Option value={10}>Álnevek</Option>
                  <Option value={11}>Adateltérések</Option>
                  <Option value={12}>Egyéb adatok</Option>
                  <Option value={13}>Hivatkozások</Option>
                  <Option value={14}>Kitüntetések</Option>
                </Select>
              </Form.Item>
            </Col>



            <Col xs={24} xl={24} md={8}>
              <Form.Item>
                <Space>
                  <Button style={{ width: "100%" }} htmlType="submit" type="primary">
                    Szűrés</Button>
                </Space>
              </Form.Item>
            </Col>
          </Form>

        </Row>

      </>
    );
  };
  const printRef = React.useRef<HTMLDivElement>(null!);
  const handleDownloadPdf = async () => {
    const element = printRef.current;
    const canvas = await html2canvas(element);
    const data = canvas.toDataURL('image/JPEG');

    const pdf = new jsPDF();
    const imgProperties = pdf.getImageProperties(data);
    const pdfWidth = pdf.internal.pageSize.getWidth();
    const pdfHeight =
      (imgProperties.height * pdfWidth) / imgProperties.width;

    pdf.addImage(data, 'JPEG', 10, 5, pdfWidth, pdfHeight);
    pdf.save(`${record?.nev}.pdf`);
  };

  const onChange2: TableProps<IMekdata2>['onChange'] = (pagination, filters, sorter, extra) => {
    console.log('params', pagination, filters, sorter, extra);
  };

  return (
    <>
      <Title level={3}>Szűrési feltételek</Title>
      <Row gutter={[16, 16]}>
        <Col xl={22} lg={24} xs={24}>
          <Card style={{ borderColor: "#001529" }}>
            <Filter formProps={searchFormProps} />
          </Card>
        </Col>

        <Col xl={35} xs={24}>

          <List title={<Title level={3}></Title>}>
            <Tabs hidden={isActive == false} defaultActiveKey="1" items={items} onChange={onChange} />
          </List>

        </Col>
      </Row>
      {/* --------------- Személyek megtekintése --------------- */}

      <Modal visible={visible} closable={false} width={1000} footer={null} >
        <Show isLoading={isLoading}
          headerButtons={<RefreshButton hidden />}
          headerProps={{
            onBack: () => setVisible(false)
          }}
          resource="peoples"
          recordItemId={showId}>
          <div style={{ display: 'block' }} ref={printRef}>

            <Row >
              <Col style={{ paddingBottom: 20 }} xs={12} lg={6}>
                <Title level={5}>Név</Title>
                <Text>{record?.nev}</Text>
              </Col>
              <Col style={{ paddingBottom: 20 }} xs={12} lg={6}>
                <Title level={5}>Születési év</Title>
                <Text>{record?.szev == null ? "Nincs adat" : record?.szev}</Text>
              </Col>
              <Col style={{ paddingBottom: 20 }} xs={12} lg={6}>
                <Title level={5}>Hónap</Title>
                <Text>{record?.szho == null ? "Nincs adat" : record?.szho}</Text>
              </Col>
              <Col style={{ paddingBottom: 20 }} xs={12} lg={6}>
                <Title level={5}>Nap</Title>
                <Text>{record?.sznap == null ? "Nincs adat" : record?.sznap}</Text>
              </Col>
              <Col style={{ paddingBottom: 20 }} xs={12} lg={6}>
                <Title level={5}>Halálozási év</Title>
                <Text>{record?.hev == null ? "Nincs adat" : record?.hev}</Text>
              </Col>
              <Col style={{ paddingBottom: 20 }} xs={12} lg={6}>
                <Title level={5}>Hónap</Title>
                <Text>{record?.hho == null ? "Nincs adat" : record?.hho}</Text>
              </Col>
              <Col style={{ paddingBottom: 20 }} xs={12} lg={6}>
                <Title level={5}>Nap</Title>
                <Text>{record?.hnap == null ? "Nincs adat" : record?.hnap}</Text>
              </Col>
            </Row>

            <br></br>
            <Text>
              {record?.peopleDatas.map(item => <p key={item.id}>{item.tnev}</p>)}
            </Text>

            <Table
              pagination={false}
              columns={columns}
              rowKey="id"
              dataSource={record?.peopleDatas}
              onChange={onChange2}
            />
          </div>
          <br></br>
          <Button icon={<Icons.FilePdfOutlined style={{ fontSize: "18px" }} />} type="primary" onClick={handleDownloadPdf}>Letöltés</Button>

        </Show>
      </Modal>
      {/* --------------- Személyek megtekintése --------------- */}
    </>
  );

};

