import React, { useEffect } from "react";

import { AuthProvider, Refine } from "@pankod/refine-core";
import {
  notificationProvider,
  Layout,
  ReadyPage,
  ErrorComponent,
} from "@pankod/refine-antd";
import "@pankod/refine-antd/dist/reset.css";
//import hu_HU from "utility/antdLocale_HU";

import routerProvider from "@pankod/refine-react-router-v6";
import { useTranslation } from "react-i18next";
import { Header } from "components/layout";
import { Kereses } from "pages/kereses/list";
import { Title } from "components/layout/title";
import { Utmutato } from "pages/utmutato/list";
import { Login } from "pages/login";
import { KeresesShow } from "pages/kereses/show";
import simpleRestDataProvider from "./dataProvider";
import moment from "moment";
import "moment/locale/hu";
import dayjs from "dayjs";
import { ConfigProvider } from 'antd';
import hu_HU from 'antd/locale/hu_HU';

function App() {
  const { t, i18n } = useTranslation();
  //const API_URL = "http://127.0.0.1:8000/api";
  const API_URL = "/api";

  const dataProvider = simpleRestDataProvider(API_URL);

  const i18nProvider = {
    translate: (key: string, params: object) => t(key, params),
    changeLocale: (lang: string) => i18n.changeLanguage(lang),
    getLocale: () => i18n.language,
  };
 
  const locale = i18nProvider.getLocale();
  useEffect(() => {
    if (locale === "hu") {
      moment.locale("hu");
      dayjs.locale("hu");
    } else {
      moment.locale("en");
      dayjs.locale("en");
    }
    document.documentElement.lang = locale;            
  }, [locale]);
  const mockUsers = [
    {
      username: "admin",
      password: "admin",
      roles: ["admin"],
    }
  ];
  
  const authProvider: AuthProvider = {
    login: ({ username, password, remember }) => {
      // Suppose we actually send a request to the back end here.

      const user = mockUsers.find((item) => item.username === username && item.password === password);

      if (user) {
        localStorage.setItem("auth", JSON.stringify(user));
        return Promise.resolve();
      }

      return Promise.reject();
    },
    getPermissions: () => {
      const auth = localStorage.getItem("auth");
      if (auth) {
        const parsedUser = JSON.parse(auth);
        return Promise.resolve(parsedUser.roles);
      }
      return Promise.reject();
    },
    logout: () => {
      localStorage.removeItem("auth");
      return Promise.resolve();
    },
    checkError: (error) => {
      if (error && error.statusCode === 401) {
        return Promise.reject();
      }

      return Promise.resolve();
    },
    checkAuth: () =>
      localStorage.getItem("auth") ? Promise.resolve() : Promise.reject(),
    getUserIdentity: () => {
      const auth = localStorage.getItem("auth");
      if (auth) {
        const parsedUser = JSON.parse(auth);
        return Promise.resolve(parsedUser.username);
      }
      return Promise.reject();
    },
  };

  return (
      <ConfigProvider locale={hu_HU}>
        <Refine
          authProvider={authProvider}
          dataProvider={dataProvider}
          notificationProvider={notificationProvider}
          Layout={Layout}
          ReadyPage={ReadyPage}
          catchAll={<ErrorComponent />}
          routerProvider={routerProvider}
          i18nProvider={i18nProvider}
          Header={Header}
          Title={Title}
          LoginPage={Login}
          resources={[
            {
              name:"peoples",
              list: Kereses,
              show: KeresesShow
            },
            {
              name:"utmutato",
              list: Utmutato
            }
          ]}
        />
        </ConfigProvider>
  );
}

export default App;
