import {
    List,
    TextField,
    TagField,
    DateField,
    Table,
    useTable,
    Typography,
    Row,
    Col,
    RadioChangeEvent,
    FormProps,
    Form,
    Drawer,
    Card,
    Button,
    Space
} from "@pankod/refine-antd";
import React, { useState } from 'react';

import { IPost } from "interfaces";

export const Utmutato: React.FC = () => {
    const { Text, Title } = Typography;
    const [open, setOpen] = useState(false);

    const showDrawer = () => {
        setOpen(true);
    };

    const onClose = () => {
        setOpen(false);
    };
    return (
        <List title={<Title level={2}>Keresési útmutató</Title>}>
            <Text>A Magyar Életrajzi Kalauz adatbázis keresőfelülete három nagyobb részre tagolható.</Text>
            <br></br>
            <br></br>
            <Button type="primary" onClick={showDrawer}>Linkek</Button>
            <Drawer title="Linkek" placement="right" onClose={onClose} open={open}>
                <Title style={{color:'red'}} level={4}>Források</Title>
                <br></br>
                <a target={"_blank"} href="https://docs.google.com/spreadsheet/ccc?key=0AuEJfRZ7H0oPdFdHQnY3OXZSVFR4V3g4b3NPVG16a3c"><Text>● Forráskód</Text></a>
                <br></br>
                <a target={"_blank"} href="https://docs.google.com/spreadsheet/ccc?key=0AuEJfRZ7H0oPdE1ZWWZlZmxHaUlES21Vd1RHNVFOeWc"><Text>● Forráscím</Text></a>
                <br></br>
                <a target={"_blank"} href="https://docs.google.com/spreadsheet/ccc?key=0AuEJfRZ7H0oPdFNtUXc2c0plM1hGX1ZVNlhoSy1HS2c"><Text>● Forrásév</Text></a>
                <br></br>
                <a target={"_blank"} href="https://drive.google.com/file/d/1ZrJ9ah9RotieHPTsQELW5_ZQ5SD0bUsE/view?usp=sharing"><Text>● Méksajt</Text></a>
                <br></br>
                <a target={"_blank"} href="https://docs.google.com/spreadsheet/ccc?key=0AuEJfRZ7H0oPdDB2a0pZdW1oOHdxY3Q1YTFIYUl4Z0E"><Text>● Mékadattár</Text></a>
                <br></br>
                <br></br>
                <Title style={{color:'red'}} level={4}>Kitüntetések</Title>
                <br></br>
                <a target={"_blank"} href="https://docs.google.com/spreadsheet/ccc?key=0AuEJfRZ7H0oPdFVub0pzdVFOWnl5NWN4MjZhRzNxb1E"><Text>● Kitüntetéskód</Text></a>
                <br></br>
                <a target={"_blank"} href="https://docs.google.com/spreadsheet/ccc?key=0AuEJfRZ7H0oPdFAwOWdVSlg5UGE3QXJLN0Y3bExydEE"><Text>● Kitüntetéscím</Text></a>
                <br></br>
                <br></br>
                <Title style={{color:'red'}} level={4}>Kategóriák</Title>
                <br></br>
                <a target={"_blank"} href="http://mabi.aspnet.hu/Account/foglkat.pdf"><Text>● Foglalkozás</Text></a>
                <br></br>
                <a target={"_blank"} href="https://docs.google.com/document/d/1wyz2hNSHsqEQM7D2PWCJI03-AeKgSWX9V_XMShCNVC4/edit?usp=sharing"><Text>● Forrás</Text></a>
                <br></br>
                <br></br>
                <Title style={{color:'red'}} level={4}>Rövidítések</Title>
                <br></br>
                <a target={"_blank"} href="https://docs.google.com/spreadsheet/ccc?key=0AuEJfRZ7H0oPdGVoYlNJS1p6bjA5YXQyWWpUYVBicVE"><Text>● Kifejezés</Text></a>
                <br></br>
                <a target={"_blank"} href="https://docs.google.com/spreadsheet/ccc?key=0AuEJfRZ7H0oPdEJvWUxwaWhtaGxTQ3UyN0U5ZU9BNVE"><Text>● Rövidítés</Text></a>
            </Drawer>
            <br></br>
            <br></br>
            <Title level={4}>Keresési paraméterek kitöltése</Title>
            <Title style={{ marginLeft: 20 }} level={5}>1. Névre történő keresés</Title>

            <Text style={{ marginLeft: 30 }}>A keresőfelület legfelső részében található keresőmezőben három választási lehetőség áll a használó rendelkezésére:</Text>
            <br></br>
            <br></br>

            <Text style={{ marginLeft: 30 }}><strong style={{ color: "red" }}>1. Bármely előfordulás:</strong> a beírt névelem a leválogatásra került személyek nevében bárhol előfordulhat. Ezzel a lehetőséggel élhetünk, ha utónevekre akarunk keresni vagy nem vagyunk biztosak a keresendő személy nevének írásmódjában.</Text>
            <br></br>
            <br></br>
            <Text style={{ marginLeft: 30 }}><strong style={{ color: "red" }}>2. Teljes megegyezés: </strong> pontosan a beírt keresőkifejezés szerint kerül leválogatásra a feltételnek megfelelő személyek köre az adatbázisból. Használata akkor célszerű, ha biztosan tudjuk a keresendő személy nevét.</Text>
            <br></br>
            <br></br>
            <Text style={{ marginLeft: 30 }}><strong style={{ color: "red" }}>3. Mező elejével megegyező: </strong> a keresendő személy(ek) neve a beírt karakterekkel kezdődik.</Text>
            <br></br>
            <br></br>
            <Text style={{ marginLeft: 30 }}>A választás után a keresőmező alján található "szűrés" gombra kattintva elindíthatjuk a leválogatást.</Text>
            <br></br>
            <br></br>
            <Title style={{ marginLeft: 20 }} level={5}>2. Dátum szerinti keresés</Title>

            <Text style={{ marginLeft: 30 }}> Ez a lehetőség a keresendő születési és /vagy halálozási időpont(ok) beállítására szolgál. Két sorban is kitölthetjük a paramétereket, amelyek egymással történő kombinálására is lehetőség van.</Text>
            <br></br>
            <br></br>
            <Text style={{ marginLeft: 30 }}>A felső sorban találhatjuk a születési adatok kitöltésére szolgáló mezőket, alatta pedig a halálozási adatokhoz tartozó paramétereket tölthetjük ki. A paraméterek kombinálására is van lehetőség.</Text>
            <br></br>
            <Text style={{ marginLeft: 30 }}>A születési és halálozási mezők között találhatunk egy lenyíló menüt, amelyben be tudjuk állítani a kapcsolatot a két évszám között. A dátumok összekapcsolására 3 logikai operátor áll rendelkezésünkre: „és”, „vagy”, „de nem”.</Text>
            <br></br>
            <br></br>

            <Title style={{ marginLeft: 20 }} level={5}>3. Keresési szempontok kiválasztása</Title>
            <div style={{ marginLeft: 30 }}>
                <Text>A keresőfelület harmadik része az első kettőtől eltérően az adatbázisban előre meghatározott szempontok alapján feltárt adatok kiválasztására szolgál. Alapértelmezettként „és” logikai operátorral kapcsolja össze az adatokat. Itt is szintén 3 logikai operátor áll rendelkezésünkre, az „és” operátoron kívül a „vagy”, „de nem” operátorok közül választhatunk.</Text>
            </div>
            <br></br>
            <div style={{ marginLeft: 30 }}>
                <Text >A feltétel hozzáadása gombra rányomva megjelenik 2 üres mező, valamint a mezők előtt található 2 gomb. Az első gombra kattintva egy felugró ablak fogad minket, ahol több lehetőségünk is van a szempontokra szűrni. Az ablak tetején található 3 keresőmező működése megegyezik az első pontban leírt mezőkkel.</Text>
            </div>
            <br></br>
            <div style={{ marginLeft: 30 }}>
                <Text >A keresőmezők alatt található lenyitható menüben láthatjuk a válaszható szempontokat. Ezekre kattintva ki tudjuk választani számunkra tetszőleges feltételt. A keresést elindítani a „szűrés” gombbal tudjuk. Miután lefuttattuk a keresést, akkor a számunkra tetszőleges szempontot válasszuk ki.</Text>
            </div>
            <div style={{ marginLeft: 30 }}>
                <Text >A táblázatban a sor elején találunk egy kört, amelyre, ha ráklikkelünk ezzel kiválasztjuk azt. Az „alkalmazás” gombra kattintva az ablak bezáródik és a szempont bekerül a keresésbe. Ha még szeretnénk hozzáadni feltételt a gombra kattintva megtehetjük ezt. Az operátorokat kombinálhatjuk is.</Text>
            </div>
        </List>
    );
};
